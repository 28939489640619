export const availableData = [
    {
      title: "Frontend Enginneering",
      content: "With over 4 years experience, and passion for creating user-centric web experiences and a strong technical background in HTML, CSS, JavaScript, and popular frameworks such as React and VueJs, I bring a unique combination of technical expertise and design acumen to every project.",
      stacks: [
        {item: "JavaScript / TypeScript"},
        {item: "Python"},
        {item: "React.js"},
        {item: "Next.js"},
        {item: "Vue.js"},
        {item: "GraphQL"},
        {item: "REST API"},
        {item: "Material UI"},
        {item: "Sanity CMS"},
        {item: "Strapi"},
        {item: "Tailwind"},
        {item: "Bootstrap"},
        {item: "SASS / SCSS"},
        {item: "Styled-component"},
        ]
    },

    {
        title: "Low or no-code",
        content: "I have over 6 years of combined experience on a diverse skill set including low-code and no-code expertise such as talent-outsourcing, website design using content management systems (CMS) like WordPress, Search Engine Optimization (SEO), content writing and digital advertising, I offer a well-rounded and experienced approach to digital projects.",
        stacks: [
          {item: "WordPress"},
          {item: "Shopify"},
          {item: "Wix"},
          {item: "Figma"},
          {item: "Firebase"},
          {item: "Groove Funnel"},  
          {item: "Content writing"},
          {item: "SEO"},
          {item: "Digital marketing"},
          {item: "Project mgmt"},
          {item: "Critical thinking"},
          {item: "Research"},
          ]
      }
]