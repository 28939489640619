export const BrandData = [

  {
    title: "Openwaver",
    logo: require("../assets/openwaver.svg"),
  },
  {
    title: "Ag",
    logo: require("../assets/ag.png"),
  },
  {
    title: "Homesilo",
    logo: require("../assets/homesilo.svg"),
  },

  {
    title: "Incover by Bank of Industries",
    logo: require("../assets/incover.png"),
  },

  {
    title: "Aeroseeds",
    logo: require("../assets/aeroseedLogo.svg"),
  },

  
  {
    title: "Cargodealers",
    logo: require("../assets/cargoDealers.svg"),
  },

  {
    title: "Intodoors",
    logo: require("../assets/intodoors.webp"),
  },

  {
    title: "Revetpay",
    logo: require("../assets/Revetpay.svg"),
  },

  {
    title: "HealthFeedPlus",
    logo: require("../assets/healthfeed.png"),
  },
];
