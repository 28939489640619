export const projectData = [
  {
    title: "Homesilo",
    subtitle: "A rent management solution for Landlords and tenants in Africa",
    link: "https://homesilo-africa.vercel.app",
    info: "Developed the entire frontend which include dashboards, payment logic, in-app chats etc., using Next.js, TypeScript, and GraphQL.",
    stacks: "Next.js, TypeScript, GraphQL"
  },
  {
    title: "Openwaver",
    subtitle: "A web3 software development and consulting startup",
    link: "https://openwaver.com",
    info: "Contracted by a Web3 consulting startup to their website with native blogging system using Next.js, Tailwind CSS, and Sanity.io.",
    stacks: "Next.js, Tailwind CSS, Sanity.io"
  },
  {
    title: "AG Consulting",
    subtitle: "Health and Communication Consulting Agency",
    link: "https://agconsultingmedia.com/",
    info: "Actively involved in Developing the website using WordPress and WpBakery Page builder, handled the eCommerce functionalities with Woocommerce",
    stacks: "WordPress, WooCommerce, HTML & CSS, Groove Funnels"
  },

  {
    title: "Aeroseeds Africa",
    subtitle: "A digital reforestation using UAV technology in africa",
    link: "https://aeroseeds.netlify.app/",
    info: "I was involved in implementing the frontend interface for Aeroseeds, collaborating with designers and project managers to bring it to live.",
    stacks: "React, GSAP, TailwindCSS"
    },
  {
    title: "Exx Network",
    subtitle: "Exx is a layer 1 blockchain solution (unofficial website)",
    link: "https://exx-web3.vercel.app/",
    info: "Built an web app utilizing the Exx Network, showcasing practical use cases. Implemented with Next.js, Web3.js, and TypeScript.",
    stacks: "Next.js, Web3.js, TypeScript"
  },
  {
    title: "Haelo",
    subtitle: "Haelo lets you connect with celebrities, socialize with friend, buy and sell on the internet",
    link: "http://haeloapp.vercel.app/",
    info: "Contributed to buiding a platform for reaching out to influencers, chatting, with a touch of social ecommerce feature.",
    stacks: "React.js, Styled Components"
  },
  {
    title: "Cargodealers",
    subtitle: "A logistics and warehouse service provider in Nigeria",
    link: "https://frontend-omega-ebon.vercel.app/",
    info: "Created a responsive and sleek landing page for a logistics and warehousing service provider using React.js, Tailwind CSS, and SCSS.",
    stacks: "React.js, Tailwind CSS, SCSS"
  },

  


  {
    title: "Talkroom (Clone)",
    subtitle: "A platform that let's you Build direct, meaningful connections.",
    link: "https://earone.netlify.app/",
    info: "An application that enables direct communication with favorite influencers. Utilized HTML, CSS, JavaScript and GSAP.",
    stacks: "HTML, CSS, JavaScript, GSAP"
  },

   
  {
    title: "Zuri-loki",
    subtitle: "A landing page for an e-learning platform",
    link: "https://zuri-loki.netlify.app/",
    info: "A Zuri Internship task by team LOKI. I handled the frontend with HTML, CSS and JavaScript. The project was in top 10.",
    stacks: "HTML, CSS, JavaScript, GSAP"
  },
];

export const FunProjects = [
 
  {
    title: "Zuri-loki (eLearning)",
    link: "https://zuri-loki.netlify.app/"
  },
  
  {
    title: "DataUp",
    link: "https://dataup.netlify.app/"
  },

  {
    title: "Talkroom (unofficial)",
    link: "https://earone.netlify.app/"
  },
  {
    title: "Potatoes Finance",
    link: "https://dank2021.netlify.app"
  },
  
]